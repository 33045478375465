import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import "../index.css";


export default function Careers() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
                <h2 className="col-title" style={{borderBottom: '1px solid black'}}>CAREERS</h2>
                {/* <p style={{textAlign: 'justify'}}>We use innovative application development techniques including agile to enhance the life-cycle of the entire development process.</p> */}
                <p>If you would you like to work in an exciting industry with a lot of growth potential, apply directly below. <br/><br/><h4>Job Openings</h4></p><br/>

                <div>

<MediaQuery minWidth={930}>
<table style={{width: '100%', border: '1px solid #e1e0e0', background: '#f4f6f8'}}>
<tbody>
<tr>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job ID</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job Title</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Location</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Posted On</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Proceed</th>
</tr>
{/* <tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0608</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Software Engineer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Boulder, CO, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/f9a3735e" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0609</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Senior Dot Net Developer with Azure</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/9a0590e0" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0610</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Auditor</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Macon, GA, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/jobs/eigennet-llc-03ad0c67/auditor-b63e975d" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0611</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Transport Specialist</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/quiz/preview/fee24e69" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr> */}

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0612</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Program Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 17th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/e5a81f96" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0613</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Technical Lead</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 17th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/7ac0acf9" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0614</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Supervisor Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 20th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/38214b27" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0615</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Program Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 20th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/763805ca" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0616</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Systems Administrator</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Dec 06th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/e0a5ce41" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0617</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>General Clerk</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Atlanta, GA, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Mar 01st, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/a32f84e0" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>
</tbody>
</table>
</MediaQuery>


<div className="row">
<MediaQuery maxWidth={929}>
  <div className="col-sm-12 col-md-12 col-lg-12" style={{overflowX:'scroll'}}>
<table className="col-sm-12 col-md-12 col-lg-12" style={{border: '1px solid #e1e0e0', background: '#f4f6f8',}}>
<tbody>
<tr>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job ID</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job Title</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Location</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Posted On</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Proceed</th>
</tr>
{/* <tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0608</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Software Engineer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Boulder, CO, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/f9a3735e" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0609</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Senior Dot Net Developer with Azure</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/9a0590e0" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0610</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Auditor</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Macon, GA, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/jobs/eigennet-llc-03ad0c67/auditor-b63e975d" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0611</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Transport Specialist</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Recently</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/quiz/preview/fee24e69" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr> */}

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0612</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Program Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 17th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/e5a81f96" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>


<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0613</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Technical Lead</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 17th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/7ac0acf9" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0614</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Supervisor Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 20th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/38214b27" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0615</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Program Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Nov 20th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/763805ca" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0616</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Systems Administrator</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Washington, DC, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Dec 06th, 2023</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/e0a5ce41" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0617</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>General Clerk</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Atlanta, GA, USA</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Mar 01st, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/a32f84e0" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>
</tbody>
</table>
</div>
</MediaQuery>
</div>
</div>
<br/>


    </div>
  )
}
